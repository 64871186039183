import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const EmpresaPage = lazy(() =>
	import("./empresa/EmpresaPage")
);

const PerfilPage = lazy(() =>
	import("./perfil/PerfilPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/empresa" component={EmpresaPage} />
				<Route path="/perfil" component={PerfilPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
