let url = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
} else {
	if (process.env.REACT_APP_AMBIENTE === 'PRD') {
		url = "https://api.tracking.kmm.com.br";
	}
	if (process.env.REACT_APP_AMBIENTE === 'HML') {
		url = "https://hml.api.tracking.kmm.com.br";
	}
}

export const Constants = {
	url: url,
	baseUrl: url + "/api/admin",
};
